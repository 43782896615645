import React from "react";
import "../styles/AboutMe.css";

function AboutMe() {
  return (
    <div className="aboutMe">
      <h1>About Me</h1>
      <div className="aboutMe__paragraph">
        <p>
          {" "}
          I am a Software Engineer, graduate from the University of Iowa with a
          Bachelor's degree in Computer Science and a Bachelor's degree in
          Enterprise Leadership. I am passionate about technology, specifically
          programming and electronics because it can always be advanced to
          improve our lifestyles.
        </p>
        <p>
          {" "}
          I have six years of programming experience in software development
          using JavaScript, C++, Java, and Python. I have implemented and
          replicated programs and apps to enhance my skills in programming. I
          have taken many courses on Udemy to enhance my knowledge as well.{" "}
        </p>
        <p>
          I have three years developing web applications using the MERN (Mongo
          DB, Express, React, Node.js) stack. I have developed online ordering
          websites two restaurants and have replicated websites to improve my
          skills and knowledge.
        </p>
        <p>
          {" "}
          I have four years exerience working with microcontrollers using
          Arduino. I have developed a remote start for my car using Arduino. I
          have worked with a 3pi robot to configure the device to be able to
          find the shortest path in a maze.{" "}
        </p>
      </div>
      <div className="aboutMe__skills">
        <div className="aboutMe__skill">
          <h2>Operating Systems</h2>
          <div className="aboutMe__icons">
            <i className="devicon-apple-original wordmark colored"></i>
            <i className="devicon-linux-plain wordmark colored"></i>
            <i className="devicon-windows8-original colored"></i>
          </div>
          <p>I work with Mac OS, Ubuntu, and Windows to develop programs.</p>
        </div>
        <div className="aboutMe__skill">
          <h2>Programming Languages</h2>
          <div className="aboutMe__icons">
            <i className="devicon-javascript-plain"></i>
            <i className="devicon-cplusplus-plain-wordmark colored"></i>
            <i className="devicon-java-plain-wordmark colored"></i>
          </div>
          <p>
            I have developed programs using JavaScript, C++, and Java. I use
            MERN (Mongo DB, Express, React, Node.js) to develop web
            applications.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
