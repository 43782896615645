import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import { Button } from "@material-ui/core";
import Resume from "../images/Resume.pages"
import "../styles/Welcome.css";

function Welcome() {
  return (
    <div className="welcome">
      <span className="welcome__name">Edwin Melendez</span>
      <span className="welcome__title">Software Engineer</span>

      <div className="welcome__links">
        <a aria-label="Send email" href="mailto:edwinmel93@gmail.com">
          <i>
            <EmailIcon />
          </i>
        </a>
        <a
          aria-label="My LinkedIn"
          href="https://www.linkedin.com/in/edwinmelendez93/"
        >
          <i>
            <LinkedInIcon />
          </i>
        </a>
        <a aria-label="My Github" href="https://github.com/edwin391">
          <i>
            <GitHubIcon />
          </i>
        </a>
      </div>

      <div className="welcome__sections">
        {/* <Button onClick={executeScroll}>About Me</Button>
        <Button>Projects</Button> */}
        {/* <a href={Resume}>
          <Button>Resume</Button>
        </a> */}
      </div>
    </div>
  );
}

export default Welcome;
