import React from "react";
import "../styles/Footer.css";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="footer">
      <p>Copyright © {year} Edwin Melendez</p>
    </div>
  );
}

export default Footer;
