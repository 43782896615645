import React from "react"
// import "./App.css";
import AboutMe from "./components/AboutMe";
import Projects from "./components/Projects";
import Welcome from "./components/Welcome";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Welcome />
      <AboutMe />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
